import React, { useState, useEffect, useReducer, useRef } from "react";
import axios from "axios";

import { farmageo_api } from "../../config";

import PantallaContext from "./context/PantallaContext";
import { requestErrorHandler } from "./context/FuncionesContext";
import { FuncionesProvider } from "./context/FuncionesContext";
import PantallaReducer, {
  initialState,
  configuracionesRef,
} from "./context/PantallaReducer";
import { useParams, useLocation } from "react-router";
import SwitchMaestro from "./components/SwitchMaestro";
import HeaderConf from "./components/HeaderConf";
import { agregarAcciones } from "./helper/funciones";
import { ModalProvider } from "./context/ModalContext";

import Debugger from "./components/Debugger";

import OrixiaSpinner from "./components/OrixiaSpinner";
import { useRefStore, useRefDataStore } from "../..";
import { addsql } from "./context/PantallaReducer";


const CancelToken = axios.CancelToken;

const Pantalla = () => {
  const [state, dispatch] = useReducer(PantallaReducer, initialState);

  const [refState, setRefState] = useRefStore();
  const [refDataState, setData] = useRefDataStore();
  // console.log(conf);
  // console.log(conf_ref);

  let { pantalla } = useParams();
  let { search, state: locationState = { filtros: {} } } = useLocation();

  const [loadingPantalla, setLoadingPantalla] = useState(true);

  const params = new URLSearchParams(search);
  let id = params.get("id");

  let qsBody = {};

  const qs = search?.split("?")[1];
  const ref = localStorage.getItem("referrer");
  const ses = sessionStorage.getItem("referrer");
  const referrer = ref
    ? JSON.parse(localStorage.getItem("referrer"))
    : undefined;
  const session = ses
    ? JSON.parse(sessionStorage.getItem("referrer"))
    : undefined;

  if (qs) {
    const qsPairs = qs.split("&");
    qsBody = (() => {
      let resBody = {};

      qsPairs.forEach((q) => {
        const kp = q.split("=");
        if (!kp[0]) return;
        resBody[kp[0]] = kp[1];
      });
      return resBody;
    })();
  }
  if (referrer) {
    localStorage.removeItem("referrer");
    sessionStorage.setItem("referrer", JSON.stringify(referrer));

    if (referrer.filtros) {
      locationState.filtros = referrer.filtros;
    }
  }
  if (session?.filtros) {
    locationState.filtros = session.filtros;
  }

  const setPantalla = (response) => {
    const opciones_de_pantalla = { ...response.data.opciones };

    response.data.configuraciones[0].opciones.orden = 1;

    agregarAcciones(
      opciones_de_pantalla,
      response.data.configuraciones[0]?.opciones
    );

    const {
      configuraciones_ref,
      configuraciones_ida,
      configuraciones_ids,
      configuraciones_tipo,
      configuraciones_padre,
      configuraciones_cab,
      configuracionesDatos,
    } = configuracionesRef(response.data);

    setData(configuracionesDatos); // estado de datos

    setRefState({
      configuraciones: response.data.configuraciones,
      configuraciones_ref,
      configuraciones_ida,
      configuraciones_ids,
      configuraciones_tipo,
      configuraciones_padre,
      configuraciones_cab,
      sql: response.data.sql,
      pantalla_id: id,
      pantalla,
      opciones_de_pantalla,
    });

    setLoadingPantalla(false);
    return response;
  };

  const cancelSource = useRef(null);

  useEffect(() => {
    setLoadingPantalla(true);

    cancelSource.current = CancelToken.source();
    axios
      .post(farmageo_api + "/pantalla/" + pantalla, qsBody, {
        params: locationState?.filtros,
        cancelToken: cancelSource.current.token,
      })
      .then((response) => {
        if (response.status >= 400) {
          requestErrorHandler(response);
          return response;
        }

        return setPantalla(response);
      })
      .catch((err) => {
        console.log("cancel request...", err);
      });
    return () => {
      cancelSource.current.cancel();
    };
  }, [pantalla, id]);

  const ref_ida = refState.configuraciones_ref[pantalla];

  useEffect(() => {
    if (ref_ida > 1) {
      setLoadingPantalla(true);
      axios
        .post(farmageo_api + "/pantalla/" + pantalla, qsBody, {
          params: locationState?.filtros,
          cancelToken: cancelSource.current.token,
        })
        .then((response) => {
          if (response.status >= 400) {
            requestErrorHandler(response);

            return response;
          }

          // dispatch({
          //   type: "SET_CONFIGURACIONES",
          //   payload: response.data.configuraciones,
          // });

          const {
            configuraciones_ref,
            configuraciones_ida,
            configuraciones_ids,
            configuraciones_tipo,
            configuraciones_padre,
            configuraciones_cab,
            configuracionesDatos,
          } = configuracionesRef(response.data);

          setData(configuracionesDatos); // estado de datos

          setRefState({
            configuraciones: response.data.configuraciones,
            configuraciones_ref,
            configuraciones_ida,
            configuraciones_ids,
            configuraciones_tipo,
            configuraciones_padre,
            configuraciones_cab,
          });

          response.data.configuraciones[0].opciones.orden = 1;

          setLoadingPantalla(false);
          return response;
        })
        .catch((err) => {
          console.log("cancel request...", err);
        })
        .then((response) => {
          // dispatch({
          //   type: "ADD_SQL",
          //   payload: response.data.sql,
          // });
          setRefState({ sql: addsql(response.data.sql, refState.sql) });
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    }
  }, [ref_ida]);

  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title =
      refState.opciones_de_pantalla.title ?? defaultTitle.current;
  }, [refState.opciones_de_pantalla]);

  return (
    <PantallaContext.Provider
      value={{
        configuraciones: refState.configuraciones,
        opciones_de_pantalla: refState.opciones_de_pantalla,
        pantalla: refState.pantalla,
        pantalla_id: refState.pantalla_id,
        configuraciones_ref: refState.configuraciones_ref,
        configuraciones_ids: refState.configuraciones_ids,
        configuraciones_cab: refState.configuraciones_cab,
        configuraciones_padre: refState.configuraciones_padre,
        data: refDataState,
        filtrosAplicados: refState.filtrosAplicados,
        PantallaDispatch: dispatch,
        loadingPantalla,
        sql: refState.sql,
        qsBody,
        refState,
      }}
    >
      <ModalProvider>
        <FuncionesProvider>
          <Debugger />

          <HeaderConf
            opciones={refState.opciones_de_pantalla}
            className="configuracion_pantalla_titulo_principal"
          />
          <div
            id={pantalla}
            style={{
              paddingBottom: "1.5rem",
              overflow: "hidden",
              minHeight: "90vh",
            }}
          >
            {loadingPantalla ? (
              <OrixiaSpinner text={"Cargando..."} />
            ) : (
              refState.configuraciones
                .sort((a, b) => a.opciones.orden - b.opciones.orden)
                .map((item, indiceConf) => {
                  return (
                    <SwitchMaestro
                      key={item.opciones.id_a}
                      _key={item.id_a}
                      configuracion={item}
                      id={id}
                      indiceConf={[indiceConf]}
                      qsBody={qsBody}
                    />
                  );
                })
            )}
          </div>
        </FuncionesProvider>
      </ModalProvider>
    </PantallaContext.Provider>
  );
};

export default Pantalla;
