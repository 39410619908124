import React, { useContext, useState } from "react";
import FuncionesContext from "../../../context/FuncionesContext";
import VistaContext from "../../context/VistaContext";

const Boton = ({
  data,
  cab,
  hijos,
  campokey,
  indiceData,
  id_elemento,
  context = VistaContext,
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const f = useContext(FuncionesContext);
  const bctx = useContext(context);

  const {
    boton_padding = "0.2rem 0.5rem",
    boton_backgroundColor = "rgba(212, 215, 217, 1)",
    boton_textAlign = "center",
    boton_cursor = "pointer",
    boton_width = "100px",
    boton_borderRadius = "15px",
    boton_image_height = "40px",
    boton_image_alt = "imagen",
    boton_texto = "Click Aqui",
    boton_color = "black",
    boton_texto_alias,
  } = cab;

  const botonStyle = {
    padding: boton_padding,
    backgroundColor: boton_backgroundColor,
    textAlign: boton_textAlign,
    cursor: boton_cursor,
    width: boton_width,
    borderRadius: boton_borderRadius,
    color: boton_color,
  };

  const handleCancelar = () => console.log("Cancelado");

  const handleClick = () => {
    let funcion = "";

    setIsClicked(true);
    setTimeout(() => {
      setIsClicked(false);
    }, 300);

    if (cab.boton_funcion_onClick) {
      funcion = cab.boton_funcion_onClick;
    }
    if (cab.boton_funcion_alias) {
      funcion = data[cab.boton_funcion_onClick_alias];
    }
    f[funcion]({ data, cab, indiceData, handleCancelar, context: bctx });
    return;
  };

  return (
    <div
      id={id_elemento}
      style={{ width: "100%", gridColumn: cab.grid_span, margin: cab.margin }}
    >
      <div
        style={{ gridColumn: cab.grid_span, width: cab.width }}
        onClick={handleClick}
        className={`btn-orixia ${isClicked ? "clicked" : ""} ${
          cab.className ?? ""
        }`}
      >
        {cab.imagen_url ? (
          <img
            style={{ cursor: "pointer" }}
            height={boton_image_height}
            src={cab.imagen_url}
            alt={boton_image_alt}
          />
        ) : (
          <div style={{ textAlign: "center" }}>
            {boton_texto_alias ? data[boton_texto_alias] : boton_texto}
          </div>
        )}
      </div>
      {hijos}
    </div>
  );
};

export default Boton;
