import React from "react";
import { Editor } from "primereact/editor";

export const TextArea = ({ data, cab, campokey, id_elemento, valor }) => {
  let classNames = data[cab.id_a + "_className"] ?? cab.className;
  return (
    <div style={{ width: "100%" }} id={id_elemento}>
      <Editor
        className={classNames}
        style={{ border: "1px solid #fff0", width: "100%" }}
        id={id_elemento}
        value={valor ?? data[campokey]}
        disabled
        readOnly
        pt={{
          toolbar: {
            className: "d-none",
            style: { width: "100%" },
          },
          content: {
            style: { width: "100%" },
          },
        }}
      />{" "}
    </div>
  );
};
