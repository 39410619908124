import React, {
  useRef,
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from "react";

export default function createFastContext(initialState) {
  function useStoreData() {
    const store = useRef(initialState);

    const get = useCallback(() => store.current, []);

    const subscribers = useRef(new Set());

    const set = useCallback((value, path) => {
      if (value.configuraciones?.length === 0) {
        console.log("setting", value);
      }
      if (!path) {
        // console.log(value, store.current)
        store.current = { ...store.current, ...value };
        subscribers.current.forEach((callback) => callback());
        return;
      }

      let current = { ...store.current };
      const keys = path.split(".");
      let nested = current;
      for (let i = 0; i < keys.length - 1; i++) {
        nested = nested[keys[i]];
      }
      nested[keys[keys.length - 1]] = value;

      let nested2 = store.current;
      for (let i = 0; i < keys.length - 2; i++) {
        nested2 = nested2[keys[i]];
      }

      nested2 = { ...nested }; // Actualiza el estado con los cambios
      store.current = { ...current };

      subscribers.current.forEach((callback) => callback());
    }, []);

    const remove = useCallback((value) => {
      // Filtrar las claves que están en store.current pero no en value
      const keysToRemove = Object.keys(store.current).filter(
        (key) => !Object.prototype.hasOwnProperty.call(value, key)
      );

      // Crear una copia de store.current sin las claves que se van a eliminar
      const updatedStore = { ...store.current };
      keysToRemove.forEach((key) => {
        delete updatedStore[key];
      });

      // Copiar las nuevas claves y valores de value en updatedStore
      Object.keys(value).forEach((key) => {
        updatedStore[key] = value[key];
      });

      // Asignar updatedStore a store.current
      store.current = updatedStore;

      // Notificar a los suscriptores sobre el cambio
      subscribers.current.forEach((callback) => callback());
    }, []);

    const subscribe = useCallback((callback) => {
      subscribers.current.add(callback);
      return () => subscribers.current.delete(callback);
    }, []);

    return {
      get,
      set,
      remove,
      subscribe,
    };
  }

  const StoreContext = createContext(null);

  function RefProvider({ children }) {
    return (
      <StoreContext.Provider value={useStoreData()}>
        {children}
      </StoreContext.Provider>
    );
  }

  function useRefStore(selector) {
    const store = useContext(StoreContext);
    if (!store) {
      throw new Error("Store not found");
    }

    const [state, setState] = useState(store.get());

    // const state = selector(store.get());

    useEffect(() => {
      return store.subscribe(() => setState(store.get()));
    });

    if (!selector) {
      return [state, store.set, store.remove];
    }

    return [selector(state), store.set, store.remove];
  }

  return {
    RefProvider,
    useRefStore,
  };
}

// selector = store => store[key]
