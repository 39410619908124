import React, { useEffect, useState } from "react";
import { Select, MenuItem, FormControl } from "@mui/material";
import Label from "./LabelF";
import Axios from "axios";
import { farmageo_api } from "../../../../../config";
import { useRefStore } from "../../../../..";
import { refrescar } from "../../../context/PantallaReducer";

export const SelectF = (props) => {
  const [store, setStore] = useRefStore();
  const [configuraciones_ref] = useRefStore((s) => s.configuraciones_ref);
  const {
    id_a,
    filtrosAAplicar,
    setFiltrosAAplicar,
    opciones: op,
    select_es_maestro,
    select_depende_de,
    select_todas,
    select_maestro_de,
  } = props;

  const [opciones, setOpciones] = useState(() => {
    if (select_todas) {
      return op;
    }
    return [{ value: " ", label: "Todas", default: true }].concat(op);
  });
  const [loading, setLoading] = useState(false);

  const f = filtrosAAplicar[select_depende_de];

  useEffect(() => {
    // setOpciones([{ value: " ", label: "Todas", default: true }].concat(op));
    if (configuraciones_ref[id_a] === 1) return;
    setLoading(() => true);
    Axios.post(farmageo_api + "/select/opciones", {
      [id_a]: filtrosAAplicar[select_depende_de],
    }).then((res) => {
      setOpciones(res.data);
      setLoading(false);
    });
  }, [configuraciones_ref[id_a]]);

  const handleChange = (valor) => {
    setFiltrosAAplicar((prevState) => {
      return { ...prevState, [id_a]: valor };
    });
    if (
      select_es_maestro === "s" &&
      select_maestro_de &&
      select_maestro_de.trim() !== ""
    ) {
      select_maestro_de.split(",").forEach((ida) => {
        setStore({ configuraciones_ref: refrescar(store, ida.trim(), [3, 4]) });
      });
      return;
    }
  };

  useEffect(() => {
    if (!f) return;
    if (select_depende_de) {
      setFiltrosAAplicar((prevState) => {
        return { ...prevState, [id_a]: " " };
      });
      setOpciones(() => {
        if (select_todas) {
          return [];
        }
        return [{ value: " ", label: "Todas", default: true }];
      });
    }
  }, [f, select_depende_de]);

  function isNumeric(value) {
    // Si el valor es un número, no es necesario convertirlo
    if (typeof value === "number") {
      return value;
    }
    if (!value) return " ";

    // Convertir el valor a un string
    value = String(value);

    // Crear una expresión regular para validar los caracteres numéricos
    const regex = /^[0-9]+$/;

    // Devolver el valor si es válido
    if (regex.test(value)) {
      return Number(value);
    }

    // Devolver un string vacío si es inválido
    return value;
  }

  return (
    <div className="filtroSelect">
      <FormControl fullWidth>
        <Label
          label={props.label}
          opcionales_null={props.opcionales_null}
          permite_null={props.permite_null}
        />
        <Select
          style={{ width: "95%", height: "32px", fontSize: "0.8rem" }}
          defaultValue={props.default}
          variant="standard"
          onChange={(e) => handleChange(e.target.value)}
          value={isNumeric(filtrosAAplicar[id_a])}
          error={props.error[id_a]}
        >
          {loading ? (
            <></>
          ) : (
            opciones &&
            opciones.map((opcion) => {
              return (
                <MenuItem
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "0.8rem",
                  }}
                  value={opcion.value}
                  key={opcion.value + opcion.label}
                  className="select_option"
                >
                  {opcion.default ? <em>{opcion.label}</em> : opcion.label}
                </MenuItem>
              );
            })
          )}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectF;
